import React from "react";
import TelephoneContactCard from "./telephoneContactCard";
import { useTranslation } from "react-i18next";
import { TelephoneContactTextStyle } from "../../styles/Contact/contactStyles";

const TelephoneContact = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h3 style={TelephoneContactTextStyle}>
        {t("contact.telephoneContactText")}
      </h3>
      <TelephoneContactCard />
    </div>
  );
};

export default TelephoneContact;
