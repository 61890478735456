export const ContactContainerStyle = {
  paddingTop: "10px",
  paddingBottom: "30px",
  minHeight: "99vh",
};

export const ContactRowStyle = {
  paddingTop: "5vh",
};

export const AddressTextStyle = {
  fontWeight: "bold",
  textAlign: "center",
};

export const AddressCardStyle = {
  backgroundColor: "#007CC2",
  marginTop: "2rem",
};

export const AddresstCardTextStyle = {
  textAlign: "center",
  color: " #e8e8e8",
  lineHeight: 2,
  wordSpacing: 4,
};

export const EmailContactTitleTextStyle = {
  fontWeight: "bold",
};

export const EmailContactParagraphTextStyle = {
  lineHeight: 2,
  wordSpacing: 4,
};

export const EmailContactTextStyle = {
  lineHeight: 2,
  wordSpacing: 4,
};

export const TextareaStyle = {
  height: "200px",
};

export const ButtonStyle = {
  paddingTop: "10px",
};

export const TelephoneContactTextStyle = {
  fontWeight: "bold",
};

export const IconStyle = {
  color: "e8e8e8",
};

export const TelephoneContactCardStyle = {
  backgroundColor: "#007CC2",
  marginTop: "3rem",
};

export const TelephoneContactCardTextStyle = {
  textAlign: "center",
  color: " #e8e8e8",
};

export const TypographyStyle = {
  fontWeight: "bold",
};
