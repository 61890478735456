import React from "react";
import { useTranslation } from "react-i18next";
import {
  languageElementDivStyle,
  languageElementImage1Style,
  languageElementImage2Style,
} from "../../styles/Navbar/navbarStyles";
import { useNavigate, useLocation } from "react-router-dom";

const LanguageElement = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  let navigate = useNavigate();
  const currentPath = location.pathname.substring(
    location.pathname.indexOf("/") + 3
  );

  function handleClickRo() {
    i18n.changeLanguage("ro");
    navigate("/ro" + currentPath);
    window.location.reload(false);
  }

  function handleClickEn() {
    i18n.changeLanguage("en");
    navigate("/en" + currentPath);
    window.location.reload(false);
  }

  return (
    <div style={languageElementDivStyle}>
      <img
        src={require("../../designImages/eng.png")}
        alt="English"
        height="15px"
        width="30px"
        style={languageElementImage1Style}
        onClick={handleClickEn}
      />
      <img
        src={require("../../designImages/ro.png")}
        alt="Romanian"
        height="15px"
        width="30px"
        style={languageElementImage2Style}
        onClick={handleClickRo}
      />
    </div>
  );
};

export default LanguageElement;
