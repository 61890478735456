import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import {
  StyledTableRow,
  StyledTableCell,
} from "../../styles/Product/ProductStyles";

const ProductTable = (props) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>{t("products.tableCodeText")}</StyledTableCell>
            <StyledTableCell align="center">
              {t("products.tableSpecificationsText")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {t("products.tablePiecesPerPackageText")}
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {props.info.map((product) => (
            <StyledTableRow
              key={product.productCode}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row">
                {product.productCode}
              </StyledTableCell>
              <StyledTableCell align="center">
                {product[t("product.varSpecifications")]}
              </StyledTableCell>
              <StyledTableCell align="center">
                {product.piecesPerPackage}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductTable;
