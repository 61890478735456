import { NavLink as Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import styled from "styled-components";

export const drawerLinkStyle = {
  textDecoration: "none",
  fontWeight: "bold",
  fontSize: "17px",
};

export const navbarStyle = {
  fontWeight: "bold",
  fontSize: "17px",
};

export const navStyle = {
  background: "#007cc2",
  height: "70px",
  display: "flex",
  justifyContent: "space-between",
  padding: "0.2rem calc((100vw - 1400px) / 2)",
  zIndex: 12,
  position: "sticky",
  width: "99vw",
};

export const searchFormStyle = {
  display: "flex",
  alignItems: "center",
  paddingLeft: "25vw",
};

export const searchFormDrawerStyle = {
  display: "flex",
  alignItems: "center",
  paddingLeft: "10vw",
};

export const searchTextStyle = {
  margin: "5px 10px 5px 0",
};

export const searchButtonStyle = {
  backgroundColor: "transparent",
  borderStyle: "none",
  color: "white",
  fontWeight: "bold",
};

export const languageElementDivStyle = {
  paddingBottom: "15px",
  position: "relative",
  width: "70px",
};

export const languageElementImage1Style = {
  cursor: "pointer",
  position: "absolute",
  left: 0,
};

export const languageElementImage2Style = {
  cursor: "pointer",
  position: "absolute",
  right: 0,
};

export const logoImageStyle = {
  width: "100%",
  height: undefined,
  aspectRatio: "3218 / 641",
  alignSelf: "center",
  resizeMode: "contain",
};

export const logoStyle = {
  paddingTop: "20px",
  paddingBottom: "20px",
};

export const motoImageStyle = {
  width: "100%",
  height: undefined,
  aspectRatio: "1575 / 192",
  alignSelf: "center",
  resizeMode: "contain",
};

export const motoStyle = {
  paddingTop: "20px",
  paddingBottom: "20px",
};

export const NavLink = styled(Link)`
  color: #e8e8e8;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &:hover {
    color: #a9a9a9;
  }
  &.active {
    color: #101010;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #e8e8e8;
  padding: 1px;
  @media screen and (max-width: 768px) {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
