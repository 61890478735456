export const Text1Style = {
  textAlign: "left",
  color: " #e8e8e8",
  paddingTop: "10px",
  lineHeight: 1,
  wordSpacing: 4,
};

export const Text2Style = {
  textAlign: "left",
  color: " #e8e8e8",
  paddingBottom: "10px",
  lineHeight: 1,
  wordSpacing: 4,
};

export const IconStyle = {
  color: "e8e8e8",
};

export const IconColStyle = {
  textAlign: "right",
  paddingBottom: "10px",
  paddingTop: "5px",
};

export const FooterStyle = {
  background: "#007CC2",
  width: "99vw",
};
