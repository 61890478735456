import React from "react";
import useCollapse from "react-collapsed";
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import {
  SubcategoriesTextStyle,
  SubcategoriesCheckboxStyle,
  CategoriesTextStyle,
  CategoriesCheckboxStyle,
  IconStyle,
  SubcategoryStyle,
} from "../../styles/Products/productsStyles";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const Subcategories = (props) => {
  const { t, i18n } = useTranslation();

  const [subcategories, setSubcategories] = useState([]);
  const [activeSubcategories, setActiveSubcategories] = React.useState([]);
  const [enableToggle, setEnableToggle] = useState([]);
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  useEffect(() => {
    const regex = /\/([a-zA-Z0-9]{2})\//;
    const match = regex.exec(window.location.href);
    var subcategoryName = "";
    var categoryName = "";
    if (match) {
      if (match[1] == "en") {
        subcategoryName = "subcategoriesEng";
        categoryName = "nameEng";
      } else {
        subcategoryName = "subcategoriesRo";
        categoryName = "nameRo";
      }
    } else {
      if (i18n.language == "en") {
        subcategoryName = "subcategoriesEng";
        categoryName = "nameEng";
      } else {
        subcategoryName = "subcategoriesRo";
        categoryName = "nameRo";
      }
    }

    const getData = () => {
      fetch("/locales/fileDatabase/database.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          const subcategories = myJson.categoriesJson
            .filter((obj) => !props.category.localeCompare(obj[categoryName]))
            .map((obj) => obj[subcategoryName])
            .toString()
            .split(",");
          setSubcategories(subcategories);
          setActiveSubcategories(Object.create(subcategories));
          setEnableToggle(false);
        })
        .catch((error) => {
          console.log("error: " + error);
          this.setState({ requestFailed: true });
        });
    };

    getData();
  }, []);

  function getChecked(subcategory) {
    if (activeSubcategories.includes(subcategory)) return true;
    else {
      return false;
    }
  }

  function getToggle() {
    if (enableToggle === true) {
      return true;
    } else {
      return false;
    }
  }

  function handleClick(e) {
    if (activeSubcategories.includes(e.target.value)) {
      const index = activeSubcategories.indexOf(e.target.value);
      activeSubcategories.splice(index, 1);
    } else {
      activeSubcategories.push(e.target.value);
    }
    props.changeSubcategoriesFunction(e.target.value);
  }

  function handleCategoryClick(e) {
    if (e.target.checked === true) {
      for (var i = 0; i < subcategories.length; i++) {
        if (!activeSubcategories.includes(subcategories[i])) {
          props.changeSubcategoriesFunction(subcategories[i]);
        }
      }
      setEnableToggle(false);
      setActiveSubcategories(Object.create(subcategories));
    } else {
      for (var j = 0; j < activeSubcategories.length; j++) {
        props.changeSubcategoriesFunction(activeSubcategories[j]);
      }
      setEnableToggle(true);
      setActiveSubcategories([{}]);
    }
    props.changeCategoriesFunctionHandler(e);
  }

  return (
    <div style={SubcategoryStyle}>
      <div className="collapsible">
        <Checkbox
          checked={props.isCategoryChecked}
          style={CategoriesCheckboxStyle}
          value={props.category}
          onChange={handleCategoryClick}
        />
        {/* TODO: remove this hardcoded stuff */}
        <b style={CategoriesTextStyle} {...getToggleProps()}>
          {props.category}
          {props.category !== "Auto" &&
          props.category !== "Vopsit" &&
          props.category !== "Painting" &&
          props.category !== "Garden" &&
          props.category !== "Grădină" ? (
            isExpanded ? (
              <FontAwesomeIcon icon={faCaretUp} style={IconStyle} />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} style={IconStyle} />
            )
          ) : (
            <div></div>
          )}
        </b>
        <div {...getCollapseProps()}>
          <div className="content">
            {subcategories.map((subcategory) =>
              subcategory.localeCompare("Undefined") ? (
                <div key={subcategory}>
                  <Checkbox
                    checked={getChecked(subcategory)}
                    disabled={getToggle()}
                    style={SubcategoriesCheckboxStyle}
                    size="small"
                    value={subcategory}
                    onChange={handleClick}
                  />
                  <b style={SubcategoriesTextStyle}>{subcategory}</b>{" "}
                </div>
              ) : (
                <div></div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subcategories;
