import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import {
  CardTextStyle,
  CardMediaStyle,
  CardStyle,
} from "../../styles/Products/productsStyles";
import { isIE, isEdge, isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";

const ProductCard = (props) => {
  const { i18n } = useTranslation();
  var subcategoryValue = props.subcategory;
  if (subcategoryValue == "Undefined") {
    subcategoryValue = props.category;
  } else {
    subcategoryValue = props.subcategory;
  }

  if (isIE || isEdge || isSafari) {
    return (
      <Card style={CardStyle}>
        <CardActionArea
          href={
            "https://www.perind.ro/" +
            i18n.language +
            "/product/" +
            props.subcategory
              .replace(/\s/g, "-")
              .replace(/[/]/g, "-")
              .toLowerCase()
              .replace(/[ăâ]/g, "a")
              .replace(/[ș]/g, "s")
              .replace(/[ț]/g, "t") +
            "/" +
            props.id +
            "-" +
            props.name
              .replace(/\s/g, "-")
              .replace(/[/]/g, "-")
              .toLowerCase()
              .replace(/[ăâ]/g, "a")
              .replace(/[ș]/g, "s")
              .replace(/[ț]/g, "t")
          }
        >
          <CardContent>
            <CardMedia
              style={CardMediaStyle}
              component="img"
              image={require("../../../public/locales/productImagesThumbnails/" +
                props.path +
                ".jpg")}
              alt={props.name
                .replace(/[ăâ]/g, "a")
                .replace(/[ș]/g, "s")
                .replace(/[ț]/g, "t")}
              title={props.name
                .replace(/[ăâ]/g, "a")
                .replace(/[ș]/g, "s")
                .replace(/[ț]/g, "t")}
            />
            <Typography
              style={CardTextStyle}
              gutterBottom
              variant="h5"
              component="div"
            >
              {props.name
                .replace(/[ăâ]/g, "a")
                .replace(/[ș]/g, "s")
                .replace(/[ț]/g, "t")}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  } else {
    return (
      <Card style={CardStyle}>
        <CardActionArea
          href={
            "https://www.perind.ro/" +
            i18n.language +
            "/product/" +
            subcategoryValue
              .replace(/\s/g, "-")
              .replace(/[/]/g, "-")
              .toLowerCase()
              .replace(/[ăâ]/g, "a")
              .replace(/[ș]/g, "s")
              .replace(/[ț]/g, "t") +
            "/" +
            props.id +
            "-" +
            props.name
              .replace(/\s/g, "-")
              .replace(/[/]/g, "-")
              .toLowerCase()
              .replace(/[ăâ]/g, "a")
              .replace(/[ș]/g, "s")
              .replace(/[ț]/g, "t")
          }
        >
          <CardContent>
            <CardMedia
              style={CardMediaStyle}
              component="img"
              image={require("../../../public/locales/productImagesThumbnailsAvif/" +
                props.path +
                ".avif")}
              alt={props.name
                .replace(/[ăâ]/g, "a")
                .replace(/[ș]/g, "s")
                .replace(/[ț]/g, "t")}
              title={props.name
                .replace(/[ăâ]/g, "a")
                .replace(/[ș]/g, "s")
                .replace(/[ț]/g, "t")}
            />
            <Typography
              style={CardTextStyle}
              gutterBottom
              variant="h5"
              component="div"
            >
              {props.name
                .replace(/[ăâ]/g, "a")
                .replace(/[ș]/g, "s")
                .replace(/[ț]/g, "t")}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
};
export default ProductCard;
