import React from "react";
import { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ProductCard from "../../components/Products/productCard";
import {
  FilterStyle,
  CellStyle,
  ProductPageStyle,
  ProductPageContainerStyle,
  ProductPageRowStyle,
} from "../../styles/Products/productsStyles";
import Category from "../../components/Products/categories";
import ReactPaginate from "react-paginate";
import "../../styles/Products/productPageStyles.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Products() {
  const [products, setProducts] = useState([]);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [activeCategories, setActiveCategories] = useState([]);
  const [activeSubcategories, setActiveSubcategories] = useState([]);

  var myMap = {
    Cleaning: "Curățenie",
    Kitchen: "Bucătărie",
    Bath: "Baie",
    Household: "Casnice",
    Auto: "Auto",
    Painting: "Vopsit",
    Garden: "Grădină",
    All: "All",
  };

  const { t, i18n } = useTranslation();
  let { filter } = useParams();
  const regex = /\/([a-zA-Z0-9]{2})\//;
  const match = regex.exec(window.location.href);

  if (match) {
    if (match[1] == "ro") {
      filter = myMap[filter];
    }
  } else {
    if (i18n.language == "ro") {
      filter = myMap[filter];
    }
  }

  var categoryName = "nameRo";
  var subcategoryName = "subcategoriesRo";
  var productCategoryLng = "productCategoryRo";
  var productSubcategoryLng = "productSubCategoryRo";
  var productNameLng = "productNameRo";
  //language update by url
  if (match) {
    const result = match[1];
    if (i18n.language != result) {
      i18n.changeLanguage(result);
    }
    if (match[1] == "en") {
      categoryName = "nameEng";
      subcategoryName = "subcategoriesEng";
      productCategoryLng = "productCategoryEng";
      productSubcategoryLng = "productSubCategoryEng";
      productNameLng = "productNameEng";
    } else {
      categoryName = "nameRo";
      subcategoryName = "subcategoriesRo";
      productCategoryLng = "productCategoryRo";
      productSubcategoryLng = "productSubCategoryRo";
      productNameLng = "productNameRo";
    }
  } else {
    if (i18n.language == "en") {
      categoryName = "nameEng";
      subcategoryName = "subcategoriesEng";
      productCategoryLng = "productCategoryEng";
      productSubcategoryLng = "productSubCategoryEng";
      productNameLng = "productNameEng";
    } else {
      categoryName = "nameRo";
      subcategoryName = "subcategoriesRo";
      productCategoryLng = "productCategoryRo";
      productSubcategoryLng = "productSubCategoryRo";
      productNameLng = "productNameRo";
    }
  }

  var description = filter + t("products.description");
  var keywords = t("products.keywords");

  useEffect(() => {
    window.scrollTo(0, 0);

    const getData = () => {
      fetch("/locales/fileDatabase/database.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          getCategories(myJson);
          getSubcategories(myJson);
          getProducts(myJson);
        })
        .catch((error) => {
          console.log("error: " + error);
          this.setState({ requestFailed: true });
        });
    };

    const getCategories = async (myJson) => {
      var activeCategories = [];
      if (!filter.localeCompare("All")) {
        activeCategories = myJson.categoriesJson.map(
          (obj) => obj[categoryName]
        );
        setActiveCategories(activeCategories);
      } else {
        activeCategories.push(filter);
        setActiveCategories(activeCategories);
      }
    };

    const getSubcategories = async (myJson) => {
      var activeSubcategories = [];
      activeSubcategories = myJson.categoriesJson
        .map((obj) => obj[subcategoryName])
        .toString()
        .split(",");
      setActiveSubcategories(activeSubcategories);
    };

    const getProducts = async (myJson) => {
      const products = myJson.productsJson;
      setProducts(products);

      if (!filter.localeCompare("All")) {
        const currentProducts = myJson.productsJson;
        setCurrentProducts(currentProducts);
      } else {
        const currentProducts = myJson.productsJson.filter(
          (obj) => !filter.localeCompare(obj[productCategoryLng])
        );
        setCurrentProducts(currentProducts);
      }
    };

    getData();
  }, []);

  function CalculateProducts(
    currentlyActiveCategories,
    currentlyActiveSubcategories
  ) {
    var newProducts = [];
    for (const product of products) {
      if (currentlyActiveCategories.includes(product[productCategoryLng])) {
        if (
          currentlyActiveSubcategories.includes(product[productSubcategoryLng])
        ) {
          newProducts.push(product);
        }
      }
    }
    return newProducts;
  }

  function ChangeActiveCategories(changedCategory) {
    if (activeCategories.includes(changedCategory)) {
      const index = activeCategories.indexOf(changedCategory);
      activeCategories.splice(index, 1);
    } else {
      activeCategories.push(changedCategory);
    }
    setActiveCategories(activeCategories);
    setCurrentProducts(
      CalculateProducts(activeCategories, activeSubcategories)
    );
  }

  function ChangeActiveSubcategories(changedSubcategory) {
    if (activeSubcategories.includes(changedSubcategory)) {
      const index = activeSubcategories.indexOf(changedSubcategory);
      activeSubcategories.splice(index, 1);
    } else {
      activeSubcategories.push(changedSubcategory);
    }
    setActiveSubcategories(activeSubcategories);
    setCurrentProducts(
      CalculateProducts(activeCategories, activeSubcategories)
    );
  }

  function CurrentlyDisplayedProducts(props) {
    return (
      <>
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-249926206-1"
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-249926206-1');
        `}
          </script>
          <title>{t("title.pagetitle")}</title>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
          ></meta>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords}></meta>
        </Helmet>

        {props.currentlyDisplayedProducts &&
          props.currentlyDisplayedProducts.map((currentlyDisplayedProduct) => (
            <Col
              key={currentlyDisplayedProduct.id}
              xxl={3}
              xl={4}
              lg={4}
              md={6}
              sm={6}
              xs={12}
              style={CellStyle}
            >
              <ProductCard
                key={currentlyDisplayedProduct.id}
                name={currentlyDisplayedProduct[productNameLng]}
                path={currentlyDisplayedProduct.productImagePath}
                id={currentlyDisplayedProduct.id}
                subcategory={currentlyDisplayedProduct[productSubcategoryLng]}
                category={currentlyDisplayedProduct[productCategoryLng]}
              />
            </Col>
          ))}
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(currentProducts.slice(itemOffset, endOffset));
      //setPageCount(Math.ceil(currentProducts.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset =
        (event.selected * itemsPerPage) % currentProducts.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Row>
          <CurrentlyDisplayedProducts
            currentlyDisplayedProducts={currentItems}
          />
        </Row>
        <div id="react-paginate">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            activeclassname="active-page"
          />
        </div>
      </>
    );
  }

  return (
    <div style={ProductPageStyle}>
      <Container fluid="md" style={ProductPageContainerStyle}>
        <Row style={ProductPageRowStyle}>
          <Col
            xxl={2}
            xl={3}
            lg={3}
            md={12}
            sm={12}
            xs={12}
            style={FilterStyle}
          >
            <Category
              initiallyActiveCategories={activeCategories}
              changeCategoriesFunction={ChangeActiveCategories}
              changeSubcategoriesFunction={ChangeActiveSubcategories}
            />
          </Col>
          <Col lg={true} md={true} sm={true} xs={12}>
            <PaginatedItems itemsPerPage={2000} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Products;
