import React from "react";
import { MDBFooter } from "mdbreact";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import FooterConstants from "../../constants/Footer/footerConstants.json";
import {
  Text1Style,
  Text2Style,
  IconStyle,
  IconColStyle,
  FooterStyle,
} from "../../styles/Footer/footerStyles";

const footerStyle = { paddingBottom: "10px", paddingTop: "20px" };

const Footer = () => {
  return (
    <MDBFooter style={FooterStyle}>
      <Container>
        <Row style={footerStyle}>
          <Col style={IconColStyle} xxl="2" xl="2" lg="2" md="4" sm="4" xs="4">
            <FontAwesomeIcon icon={faLocationDot} style={IconStyle} size="3x" />
          </Col>
          <Col xxl="4" xl="4" lg="4" md="8" sm="8" xs="8">
            <h6 style={Text1Style}>{FooterConstants.streetString}</h6>
            <h6 style={Text2Style}>{FooterConstants.locationString}</h6>
          </Col>
          <Col style={IconColStyle} xxl="2" xl="2" lg="2" md="4" sm="4" xs="4">
            <FontAwesomeIcon icon={faMicrophone} style={IconStyle} size="3x" />
          </Col>
          <Col xxl="4" xl="4" lg="4" md="8" sm="8" xs="8">
            <h6 style={Text1Style}>{FooterConstants.phoneString}</h6>
            <h6 style={Text2Style}>{FooterConstants.emailString}</h6>
          </Col>
        </Row>
      </Container>
    </MDBFooter>
  );
};

export default Footer;
