import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./buttonStyle.css";
import { useLocation } from "react-router-dom";

const DropdownButtonDrawer = (props) => {
  const { i18n, t } = useTranslation();
  const language = i18n.language.includes("ro") ? "ro" : "en";
  const location = useLocation();
  const currentPath = location.pathname.substring(
    location.pathname.indexOf("/") + 3
  );
  const [categories, setCategories] = useState([]);

  var myMap = {
    Curățenie: "Cleaning",
    Cleaning: "Cleaning",
    Bucătărie: "Kitchen",
    Kitchen: "Kitchen",
    Baie: "Bath",
    Bath: "Bath",
    Casnice: "Household",
    Household: "Household",
    Auto: "Auto",
    Vopsit: "Painting",
    Painting: "Painting",
    Grădină: "Garden",
    Garden: "Garden",
  };

  useEffect(() => {
    const getData = () => {
      fetch("/locales/fileDatabase/database.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          getCategories(myJson);
        })
        .catch((error) => {
          console.log("error: " + error);
          this.setState({ requestFailed: true });
        });
    };

    const getCategories = async (myJson) => {
      var categories = [];
      categories = myJson.categoriesJson.map(
        (obj) => obj[t("category.varName")]
      );
      setCategories(categories);
    };

    getData();
  }, []);

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          id={
            currentPath.split("/")[1] == "products"
              ? "drawer-dropdown-button-active"
              : "drawer-dropdown-button"
          }
        >
          {t("navbar.products") + "          "}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            id={
              currentPath == "/products/All"
                ? "dropdown-content-active"
                : "dropdown-content"
            }
            href={"/" + language + "/products/All"}
            onClick={() => props.changeDrawerFunction(true)}
          >
            {t("navbar.allProducts")}
          </Dropdown.Item>
          {categories.map((category) => (
            <Dropdown.Item
              id={
                myMap[currentPath.split("/")[2]] == myMap[category.toString()]
                  ? "dropdown-content-active"
                  : "dropdown-content"
              }
              key={category}
              href={"/" + language + "/products/" + myMap[category.toString()]}
            >
              {category}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropdownButtonDrawer;
