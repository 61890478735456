import React from "react";
import DropdownButton from "./dropdownButton";
import DrawerComponent from "./drawer";
import { useTranslation } from "react-i18next";
import "react-dropdown/style.css";
import SearchBar from "./searchBar";
import LanguageElement from "./languageElement";
import { navStyle, NavLink, NavMenu } from "../../styles/Navbar/navbarStyles";

const NavBar = () => {
  const { i18n, t } = useTranslation();
  const language = i18n.language.includes("ro") ? "ro" : "en";

  return (
    <nav
      className="navbar navbar-expand-lg primary-color sticky-top"
      style={navStyle}
    >
      <NavMenu>
        <NavLink
          to={"/" + language + "/home"}
          activeclassname="navbarButtonActive"
          className="navbarButton"
        >
          {t("navbar.home")}
        </NavLink>
        <NavLink
          to={"/" + language + "/about"}
          activeclassname="navbarButtonActive"
          className="navbarButton"
        >
          {t("navbar.about")}
        </NavLink>
        <NavLink
          to={"/" + language + "/contact"}
          activeclassname="navbarButtonActive"
          className="navbarButton"
        >
          {t("navbar.contact")}
        </NavLink>
        <DropdownButton />
        <LanguageElement />
        <SearchBar />
      </NavMenu>
      <DrawerComponent />
    </nav>
  );
};

export default NavBar;
