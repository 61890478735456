import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState, useEffect } from "react";
import ProductCard from "../../components/Products/productCard";
import { useTranslation } from "react-i18next";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function HomeProductCarousel() {
  const [products, setProducts] = useState([]);

  const regex = /\/([a-zA-Z0-9]{2})\//;
  const match = regex.exec(window.location.href);
  const { t, i18n } = useTranslation();

  var productNameLng = "productNameRo";
  var productSubcategoryLng = "productSubCategoryRo";
  var productCategoryLng = "productCategoryRo";

  //language update by url
  if (match) {
    const result = match[1];
    if (i18n.language != result) {
      i18n.changeLanguage(result);
    }
    if (match[1] == "en") {
      productSubcategoryLng = "productSubCategoryEng";
      productNameLng = "productNameEng";
      productCategoryLng = "productCategoryEng";
    } else {
      productSubcategoryLng = "productSubCategoryRo";
      productNameLng = "productNameRo";
      productCategoryLng = "productCategoryRo";
    }
  } else {
    if (i18n.language == "en") {
      productSubcategoryLng = "productSubCategoryEng";
      productNameLng = "productNameEng";
      productCategoryLng = "productCategoryEng";
    } else {
      productSubcategoryLng = "productSubCategoryRo";
      productNameLng = "productNameRo";
      productCategoryLng = "productCategoryRo";
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    const getData = () => {
      fetch("/locales/fileDatabase/database.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          getProducts(myJson);
        })
        .catch((error) => {
          console.log("error: " + error);
          this.setState({ requestFailed: true });
        });
    };

    const getProducts = async (myJson) => {
      const products = myJson.productsJson;
      setProducts(products);
    };

    getData();
  }, []);

  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={false}
      responsive={responsive}
      infinite={true}
      autoPlaySpeed={2000}
      autoPlay={true}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {products &&
        products.map((currentlyDisplayedProduct) => (
          <ProductCard
            key={currentlyDisplayedProduct.id}
            name={currentlyDisplayedProduct[productNameLng]}
            path={currentlyDisplayedProduct.productImagePath}
            id={currentlyDisplayedProduct.id}
            subcategory={currentlyDisplayedProduct[productSubcategoryLng]}
            category={currentlyDisplayedProduct[productCategoryLng]}
          />
        ))}
    </Carousel>
  );
}

export default HomeProductCarousel;
