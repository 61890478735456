import React, { useState } from "react";
import "./emailContactForm.css";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import {
  EmailContactTextStyle,
  TextareaStyle,
  ButtonStyle,
} from "../../styles/Contact/contactStyles";
import EmailContactData from "../../constants/Contact/contactConstants.json";

const EmailContactForm = () => {
  const { t } = useTranslation();

  const [status, setStatus] = useState(t("contact.buttonText"));

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser

    setStatus(t("contact.sendingMessage"));

    const { name, email, message } = e.target.elements;
    var templateParams = {
      name: name.value,
      email: email.value,
      message: message.value,
    };

    emailjs.init(EmailContactData.userId);

    emailjs
      .send(
        EmailContactData.serviceId,
        EmailContactData.templateId,
        templateParams,
        EmailContactData.userId
      )
      .then(
        (result) => {
          alert(t("contact.sentEmail"));
          setStatus(t("contact.buttonText"));
        },
        (error) => {
          alert(t("contact.failedSendingEmail"));
          setStatus(t("contact.buttonText"));
        }
      );
    document.getElementById("myForm").reset();
  };

  return (
    <div className="emailContainer">
      <form onSubmit={handleSubmit} id="myForm">
        <div>
          <label htmlFor="name">
            <b style={EmailContactTextStyle}>{t("contact.nameWord")}</b>
          </label>
          <input
            type="text"
            id="name"
            required
            placeholder={t("contact.namePlaceholder")}
          />
        </div>
        <div>
          <label htmlFor="email">
            <b style={EmailContactTextStyle}>{t("contact.emailWord")}</b>
          </label>
          <input
            type="email"
            id="email"
            required
            placeholder={t("contact.emailPlaceholder")}
          />
        </div>
        <div>
          <label htmlFor="message">
            <b style={EmailContactTextStyle}>{t("contact.messageWord")}</b>
          </label>
          <textarea
            id="message"
            required
            placeholder={t("contact.messagePlaceholder")}
            style={TextareaStyle}
          />
        </div>
        <div style={ButtonStyle}>
          <button type="submit" className="input">
            {status}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmailContactForm;
