// will use imports from products
import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {
  CellStyle,
  SearchTextStyle,
  SearchPageStyle,
  SearchPageContainerStyle,
  SearchPageRowStyle,
} from "../../styles/Products/productsStyles";
import ProductCard from "../../components/Products/productCard";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const SearchProducts = () => {
  const { t, i18n } = useTranslation();
  var description = t("search.description");
  var keywords = t("search.keywords");

  const [products, setProducts] = useState([]);
  const { word } = useParams();

  var text;
  if (!word.localeCompare("Invalid request")) {
    text = t("");
  } else {
    text = t("products.searchProductsText");
  }

  //language update by url
  const regex = /\/([a-zA-Z0-9]{2})\//;
  const match = regex.exec(window.location.href);
  var productNameLng = "productNameRo";
  var productSubcategoryLng = "productSubCategoryRo";
  var productCategoryLng = "productCategoryRo";

  //language update by url
  if (match) {
    const result = match[1];
    if (i18n.language != result) {
      i18n.changeLanguage(result);
    }
    if (match[1] == "en") {
      productSubcategoryLng = "productSubCategoryEng";
      productNameLng = "productNameEng";
      productCategoryLng = "productCategoryEng";
    } else {
      productSubcategoryLng = "productSubCategoryRo";
      productNameLng = "productNameRo";
      productCategoryLng = "productCategoryRo";
    }
  } else {
    if (i18n.language == "en") {
      productSubcategoryLng = "productSubCategoryEng";
      productNameLng = "productNameEng";
      productCategoryLng = "productCategoryEng";
    } else {
      productSubcategoryLng = "productSubCategoryRo";
      productNameLng = "productNameRo";
      productCategoryLng = "productCategoryRo";
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    const getData = () => {
      fetch("/locales/fileDatabase/database.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          getProduct(myJson);
        })
        .catch((error) => {
          console.log("error: " + error);
          this.setState({ requestFailed: true });
        });
    };

    const getProduct = async (myJson) => {
      var finalProducts = [];
      var productsByName = [];
      var productsByCode = [];
      for (const product of myJson.productsJson) {
        if (
          product.productNameEng.toLowerCase().includes(word.toLowerCase()) ||
          product.productNameRo.toLowerCase().includes(word.toLowerCase()) ||
          product.productImagePath.toLowerCase().includes(word.toLowerCase())
        ) {
          productsByName.push(product);
        }
        for (const productInfo of product.productInfo) {
          if (
            productInfo.productCode.toLowerCase().includes(word.toLowerCase())
          ) {
            productsByCode.push(product);
            break;
          }
        }
      }

      productsByName = productsByName.filter(
        (obj) => !productsByCode.includes(obj)
      );
      finalProducts = productsByName.concat(productsByCode);

      setProducts(finalProducts);
    };

    getData();
  }, []);

  function CurrentlyDisplayedProducts(props) {
    return (
      <>
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-249926206-1"
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-249926206-1');
        `}
          </script>
          <title>{t("title.pagetitle")}</title>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
          ></meta>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords}></meta>
        </Helmet>
        {props.currentlyDisplayedProducts &&
          props.currentlyDisplayedProducts.map((currentlyDisplayedProduct) => (
            <Col
              key={currentlyDisplayedProduct.id}
              xxl={3}
              xl={4}
              lg={4}
              md={6}
              sm={6}
              xs={12}
              style={CellStyle}
            >
              <ProductCard
                key={currentlyDisplayedProduct.id}
                name={currentlyDisplayedProduct[productNameLng]}
                path={currentlyDisplayedProduct.productImagePath}
                id={currentlyDisplayedProduct.id}
                subcategory={productSubcategoryLng}
                category={currentlyDisplayedProduct[productCategoryLng]}
              />
            </Col>
          ))}
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(products.slice(itemOffset, endOffset));
      //setPageCount(Math.ceil(products.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % products.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Row>
          <CurrentlyDisplayedProducts
            currentlyDisplayedProducts={currentItems}
          />
        </Row>
        <div id="react-paginate">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            activeclassname="active-page"
          />
        </div>
      </>
    );
  }

  return (
    <div style={SearchPageStyle}>
      <Container fluid="md" style={SearchPageContainerStyle}>
        <Row style={SearchPageRowStyle}>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <h2 style={{ SearchTextStyle }}>
              {text} <b>"{word}"</b>
            </h2>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <PaginatedItems itemsPerPage={2000} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SearchProducts;
