import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./buttonStyle.css";
import "../../styles/Navbar/navbarStyles.css";
import { useLocation } from "react-router-dom";

const DropdownButton = () => {
  const { i18n, t } = useTranslation();
  const language = i18n.language.includes("ro") ? "ro" : "en";
  const location = useLocation();
  const currentPath = location.pathname.substring(
    location.pathname.indexOf("/") + 3
  );
  const [categories, setCategories] = useState([]);

  var myMap = {
    Curățenie: "Cleaning",
    Cleaning: "Cleaning",
    Bucătărie: "Kitchen",
    Kitchen: "Kitchen",
    Baie: "Bath",
    Bath: "Bath",
    Casnice: "Household",
    Household: "Household",
    Auto: "Auto",
    Vopsit: "Painting",
    Painting: "Painting",
    Grădină: "Garden",
    Garden: "Garden",
  };

  useEffect(() => {
    const regex = /\/([a-zA-Z0-9]{2})\//;
    const match = regex.exec(window.location.href);
    var categoryName = "nameRo";
    if (match) {
      if (match[1] == "en") {
        categoryName = "nameEng";
      } else {
        categoryName = "nameRo";
      }
    }

    const getData = () => {
      fetch("/locales/fileDatabase/database.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          var categories = [];
          categories = myJson.categoriesJson.map((obj) => obj[categoryName]);
          setCategories(categories);
        })
        .catch((error) => {
          console.log("error: " + error);
          this.setState({ requestFailed: true });
        });
    };

    getData();
  }, []);

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle id="navbarButton">
          {t("navbar.products")}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            id={
              currentPath == "/products/All"
                ? "dropdown-content-active"
                : "dropdown-content"
            }
            href={"/" + language + "/products/All"}
          >
            {t("navbar.allProducts")}
          </Dropdown.Item>
          {categories.map((category) => (
            <Dropdown.Item
              id={
                myMap[currentPath.split("/")[2]] == myMap[category.toString()]
                  ? "dropdown-content-active"
                  : "dropdown-content"
              }
              key={category}
              href={"/" + language + "/products/" + myMap[category.toString()]}
            >
              {category}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropdownButton;
