import React from "react";
import Parser from "html-react-parser";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import {
  AboutTextStyle,
  AboutRowStyle,
  AboutBackgroundStyle,
  AboutContainerStyle,
} from "../../styles/About/aboutStyles";

const About = () => {
  const { t, i18n } = useTranslation();

  const title = t("navbar.about")
    .replace(/[ăâ]/g, "a")
    .replace(/[ș]/g, "s")
    .replace(/[ț]/g, "t");
  var description = t("about.description");
  var keywords = t("about.keywords");
  var cannonicalTag = "";
  useEffect(() => {
    window.scrollTo(0, 0);

    //language update by url
    const regex = /\/([a-zA-Z0-9]{2})\//;
    const match = regex.exec(window.location.href);

    if (match) {
      const result = match[1];
      if (i18n.language != result) {
        i18n.changeLanguage(result);
      }
    }
  }, []);

  return (
    <div style={AboutBackgroundStyle}>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-249926206-1"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-249926206-1');
        `}
        </script>
        <title>{t("title.pagetitle")}</title>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords}></meta>
        {(cannonicalTag = "https://www.perind.ro/" + i18n.language + "/about")}
        <link rel="canonical" href={cannonicalTag} />
      </Helmet>

      <Container fluid="md" style={AboutContainerStyle}>
        <Row style={AboutRowStyle}>
          <Col xxl={7} xl={7} lg={7} md={6} sm={4} xs={0}></Col>
          <Col xxl={5} xl={5} lg={5} md={6} sm={8} xs={12}>
            <Row>
              <h1>{t("about.motoText")}</h1>
              <p style={AboutTextStyle}>{Parser(t("about.paragraph1"))}</p>
              <Typography component={"span"} variant={"body2"}>
                <h4>
                  <b>{t("about.title")}</b>
                </h4>
                <ul style={AboutTextStyle}>{Parser(t("about.offer"))}</ul>
              </Typography>
              <p style={AboutTextStyle}>{Parser(t("about.paragraph2"))}</p>
              <p style={AboutTextStyle}>{Parser(t("about.paragraph3"))}</p>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
