import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DropdownButtonDrawer from "./dropdownButtonDrawer";
import { Bars } from "../../styles/Navbar/navbarStyles";
import "../../styles/Navbar/navbarStyles.css";
import { useNavigate, useLocation } from "react-router-dom";

function DrawerComponent() {
  const { t, i18n } = useTranslation();
  const language = i18n.language.includes("ro") ? "ro" : "en";
  const location = useLocation();
  let navigate = useNavigate();
  const currentPath = location.pathname.substring(
    location.pathname.indexOf("/") + 3
  );

  function handleClickRo() {
    i18n.changeLanguage("ro");
    navigate("/ro" + currentPath);
  }

  function handleClickEn() {
    i18n.changeLanguage("en");
    navigate("/en" + currentPath);
  }

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Drawer
        open={openDrawer}
        PaperProps={{
          sx: { width: "50%" },
        }}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link
                to={"/" + language + "/home"}
                id={
                  currentPath == "/home" || currentPath == ""
                    ? "drawer-dropdown-content-active"
                    : "drawer-dropdown-content"
                }
              >
                {t("navbar.home")}
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link
                to={"/" + language + "/about"}
                id={
                  currentPath == "/about"
                    ? "drawer-dropdown-content-active"
                    : "drawer-dropdown-content"
                }
              >
                {t("navbar.about")}
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link
                to={"/" + language + "/contact"}
                id={
                  currentPath == "/contact"
                    ? "drawer-dropdown-content-active"
                    : "drawer-dropdown-content"
                }
              >
                {t("navbar.contact")}
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText>
              <DropdownButtonDrawer changeDrawerFunction={setOpenDrawer} />
            </ListItemText>
          </ListItem>
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <h1
                id={
                  language == "en"
                    ? "drawer-dropdown-content-active"
                    : "drawer-dropdown-content"
                }
                onClick={handleClickEn}
              >
                {t("navbar.languageChangeEng")}
              </h1>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <h1
                id={
                  language == "ro"
                    ? "drawer-dropdown-content-active"
                    : "drawer-dropdown-content"
                }
                onClick={handleClickRo}
              >
                {t("navbar.languageChangeRo")}
              </h1>
            </ListItemText>
          </ListItem>
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          <Divider />
          {/* <DrawerSearchBar /> */}
        </List>
      </Drawer>

      <Bars onClick={() => setOpenDrawer(!openDrawer)}></Bars>
    </>
  );
}
export default DrawerComponent;
