import React from "react";
import { useEffect } from "react";
import HomeCarousel from "../../components/Home/carousel";
import HomeProductCarousel from "../../components/Home/productCarousel";
import {
  HomePageDivStyle,
  HomeContainerStyle,
  HomeRowStyle,
} from "../../styles/Home/homeStyles";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../styles/Home/homeStyles.css";

import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Home = () => {
  const { t, i18n } = useTranslation();
  var description = t("home.description");
  var keywords = t("home.keywords");

  useEffect(() => {
    window.scrollTo(0, 0);

    //language update by url
    const regex = /\/([a-zA-Z0-9]{2})\//;
    const match = regex.exec(window.location.href);
    if (match) {
      const result = match[1];
      if (i18n.language != result) {
        i18n.changeLanguage(result);
      }
    }
  }, []);

  return (
    <div style={HomePageDivStyle}>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-249926206-1"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-249926206-1');
        `}
        </script>
        <title>{t("title.pagetitle")}</title>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords}></meta>
      </Helmet>
      <HomeCarousel />
      <br></br>
      <br></br>
      <Container fluid="md" style={HomeContainerStyle}>
        <Row style={HomeRowStyle}>
          <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={0}></Col>
          <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={0}>
            <h4
              style={{
                paddingTop: "20%",
                fontWeight: "bold",
                display: "flex",
              }}
            >
              {t("home.despre")}
            </h4>
          </Col>
          <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={0}>
            <div
              style={{ borderLeft: "4px solid #007CC2", height: "100%" }}
            ></div>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={0}>
            <h1
              style={{
                fontSize: "1.2rem",
                lineHeight: 1.5,
                wordSpacing: 4,
                display: "inline",
              }}
            >
              {t("home.descriptorText")}
            </h1>
            <h2
              style={{
                fontSize: "1.2rem",
                lineHeight: 1.5,
                wordSpacing: 4,
                display: "inline",
              }}
            >
              {t("home.descriptorText2")}
            </h2>
          </Col>
          <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={0}></Col>
        </Row>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h2
          style={{
            fontSize: "1.6rem",
            wordSpacing: 4,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {t("home.populare")}
        </h2>
        <br></br>
        <br></br>

        <Row>
          <HomeProductCarousel></HomeProductCarousel>
        </Row>
        <br></br>
        <br></br>
        <Row>
          <img src={require("../../designImages/MediuMasina.jpg")} />
        </Row>
        <Row>
          <img src={require("../../designImages/MediuPanou.jpg")} />
        </Row>
      </Container>
    </div>
  );
};

export default Home;
