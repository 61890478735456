import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AddressTextStyle,
  AddressCardStyle,
  AddresstCardTextStyle,
} from "../../styles/Contact/contactStyles";
import Constants from "../../constants/Contact/contactConstants.json";

const AddressInfo = () => {
  const { t } = useTranslation();

  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <h3 style={AddressTextStyle}>{t("contact.title")}</h3>
      <Card style={AddressCardStyle}>
        <CardActionArea>
          <CardContent style={AddresstCardTextStyle}>
            <Typography style={{ fontWeight: "bold" }}>
              {t("contact.addressTitle")}
            </Typography>
            <br></br>
            <Typography>
              {t("contact.streetWord")} {Constants.street}
              <br></br>
              {t("contact.cityWord")} {Constants.city}
              <br></br>
              {t("contact.countryWord")}
              {Constants.country}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default AddressInfo;
