import React, { Suspense } from "react";
import Navbar from "./components/Navbar/navbarElement";
import Logo from "./components/Navbar/logo";
import Footer from "./components/Footer/footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./i18n";

import Home from "./pages/Home/homePage";
import About from "./pages/About/aboutPage";
import Contact from "./pages/Contact/contactPage";
import Products from "./pages/Products/productsPage";
import ProductPage from "./pages/Product/productPage";
import SearchProducts from "./pages/SearchProducts/searchProductsPage";

import InvalidPage from "./pages/Default/404Page";
import OutdatedPage from "./pages/Default/410Page";

const App = () => {
  return (
    <Suspense fallback={null}>
      <Router>
        <Logo />
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/ro/" exact element={<Home />} />
          <Route path="/ro/search/:word" exact element={<SearchProducts />} />
          <Route path="/ro/home" exact element={<Home />} />
          <Route path="/ro/about" element={<About />} />
          <Route path="/ro/contact" element={<Contact />} />
          <Route path="/ro/products/:filter" element={<Products />} />
          <Route
            path="/ro/product/:subcategory/:id"
            element={<ProductPage />}
          />

          <Route path="/en/" exact element={<Home />} />
          <Route path="/en/search/:word" exact element={<SearchProducts />} />
          <Route path="/en/home" exact element={<Home />} />
          <Route path="/en/about" element={<About />} />
          <Route path="/en/contact" element={<Contact />} />
          <Route path="/en/products/:filter" element={<Products />} />
          <Route
            path="/en/product/:subcategory/:id"
            element={<ProductPage />}
          />

          <Route path="*" element={<InvalidPage />} status={404} />
          <Route
            path="/en/product/:id"
            element={<OutdatedPage />}
            status={410}
          />
          <Route
            path="/ro/product/:id"
            element={<OutdatedPage />}
            status={410}
          />
        </Routes>
        <Footer />
      </Router>
    </Suspense>
  );
};

export default App;
