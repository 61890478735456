import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ProductTable from "./productTable";
import {
  ProductTextStyle,
  ProductContainerStyle,
  ProductRowStyle,
} from "../../styles/Product/ProductStyles";
import { isIE, isEdge, isSafari } from "react-device-detect";

function Product(props) {
  if (isIE || isEdge || isSafari) {
    return (
      <Container style={ProductContainerStyle}>
        <Row style={ProductRowStyle}>
          <Col
            xxl={7}
            xl={7}
            lg={7}
            md={12}
            sm={12}
            xs={12}
            className="my-auto"
          >
            <img
              src={require("../../../public/locales/productImages/" +
                props.path +
                ".jpg")}
              alt={props.name}
              width="100%"
            />
          </Col>
          <Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
            <h1 style={ProductTextStyle}>
              {props.name
                .replace(/[ăâ]/g, "a")
                .replace(/[ș]/g, "s")
                .replace(/[ț]/g, "t")}
            </h1>
            <ProductTable
              name={props.name}
              path={props.path}
              info={props.info}
            />
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container style={ProductContainerStyle}>
        <Row style={ProductRowStyle}>
          <Col
            xxl={7}
            xl={7}
            lg={7}
            md={12}
            sm={12}
            xs={12}
            className="my-auto"
          >
            <img
              src={require("../../../public/locales/productImagesAvif/" +
                props.path +
                ".avif")}
              alt={props.name
                .replace(/[ăâ]/g, "a")
                .replace(/[ș]/g, "s")
                .replace(/[ț]/g, "t")}
              width="100%"
            />
          </Col>
          <Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
            <h1 style={ProductTextStyle}>
              {props.name
                .replace(/[ăâ]/g, "a")
                .replace(/[ș]/g, "s")
                .replace(/[ț]/g, "t")}
            </h1>
            <ProductTable
              name={props.name}
              path={props.path}
              info={props.info}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Product;
