export const HomePageDivStyle = {
  minHeight: "100vh",
  width: "99vw",
};

export const CarouselStyle = {
  height: "100vh",
  width: "100vw",
  objectFit: "cover",
};

export const HomeContainerStyle = {
  paddingTop: "10px",
  paddingBottom: "30px",
  //minHeight: "99vh",
};

export const HomeRowStyle = {
  paddingTop: "5vh",
};
