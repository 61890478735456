import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { CarouselStyle } from "../../styles/Home/homeStyles";
import { isIE, isEdge, isSafari } from "react-device-detect";

const HomeCarousel = () => {
  if (isIE || isEdge || isSafari) {
    return (
      <Carousel
        dynamicHeight="true"
        autoPlay={true}
        interval={6000}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
      >
        <div>
          <img
            src={require("../../designImages/bg1.jpg")}
            alt="Perind image 1"
            style={CarouselStyle}
          />
        </div>
        <div>
          <img
            src={require("../../designImages/bg2.jpg")}
            alt="Perind image 2"
            style={CarouselStyle}
          />
        </div>
        <div>
          <img
            src={require("../../designImages/bg3.jpg")}
            alt="Perind image 3"
            style={CarouselStyle}
          />
        </div>
      </Carousel>
    );
  } else {
    return (
      <Carousel
        dynamicHeight="true"
        autoPlay={true}
        interval={6000}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
      >
        <div>
          <img
            src={require("../../designImagesAvif/bg1.avif")}
            alt="carouselImage"
            style={CarouselStyle}
          />
        </div>
        <div>
          <img
            src={require("../../designImagesAvif/bg2.avif")}
            alt="carouselImage"
            style={CarouselStyle}
          />
        </div>
        <div>
          <img
            src={require("../../designImagesAvif/bg3.avif")}
            alt="carouselImage"
            style={CarouselStyle}
          />
        </div>
      </Carousel>
    );
  }
};

export default HomeCarousel;
