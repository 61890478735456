import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const ProductTextStyle = {
  lineHeight: 1.5,
  paddingBottom: 60,
  wordSpacing: 4,
  fontWeight: "bold",
  textAlign: "center",
  fontFamily: "Arial, Helvetica, sans-serif",
};

export const ProductContainerStyle = {
  paddingTop: "10px",
  paddingBottom: "60px",
  minHeight: "75vh",
  width: "99vw",
};

export const ProductRowStyle = {
  paddingTop: "5vh",
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#007cc2",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontFamily: "Arial, Helvetica, sans-serif",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Arial, Helvetica, sans-serif",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
