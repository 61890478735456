import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useEffect } from "react";

import EmailContact from "../../components/Contact/emailContact";
import TelephoneContact from "../../components/Contact/telephoneContact";
import AddressInfo from "../../components/Contact/addressInfo";
import {
  ContactContainerStyle,
  ContactRowStyle,
} from "../../styles/Contact/contactStyles";
// import ContactMap from "./contactMap";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Contact = () => {
  const { t, i18n } = useTranslation();
  var description = t("contact.description");
  var keywords = t("contact.keywords");
  var cannonicalTag = "";

  useEffect(() => {
    window.scrollTo(0, 0);

    //language update by url
    const regex = /\/([a-zA-Z0-9]{2})\//;
    const match = regex.exec(window.location.href);
    if (match) {
      const result = match[1];
      if (i18n.language != result) {
        i18n.changeLanguage(result);
      }
    }
  }, []);

  return (
    <div>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-249926206-1"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-249926206-1');
        `}
        </script>
        <title>{t("title.pagetitle")}</title>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords}></meta>
        {
          (cannonicalTag =
            "https://www.perind.ro/" + i18n.language + "/contact")
        }
        <link rel="canonical" href={cannonicalTag} />
      </Helmet>

      <Container style={ContactContainerStyle}>
        <Row style={ContactRowStyle}>
          <Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
            <Row>
              <EmailContact />
            </Row>
          </Col>
          <Col xxl={1} xl={1} lg={1} md={0} sm={0} xs={0}></Col>
          <Col xxl={4} xl={4} lg={4} md={0} sm={0} xs={0}>
            <Row>
              <TelephoneContact />
              <AddressInfo />
            </Row>
          </Col>
        </Row>
        <br></br>
        <br></br>
        <Row>
          <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
          {/* <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
          <ContactMap />
        </Col> */}
          <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
        </Row>
        <br></br>
        <br></br>
        <br></br>
      </Container>
    </div>
  );
};

export default Contact;
