import backgroundImg from "../../designImages/backgroundImg2.png";

export const AboutTextStyle = {
  lineHeight: 2,
  wordSpacing: 4,
};

export const AboutBackgroundStyle = {
  backgroundImage: `url(${backgroundImg})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "99vw",
};

export const AboutContainerStyle = {
  paddingTop: "10px",
  paddingBottom: "40px",
};

export const AboutRowStyle = {
  paddingTop: "5vh",
};
