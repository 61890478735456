import React from "react";
import { useState, useEffect } from "react";
import Subcategories from "./subcategories";
import {
  FilterTextStyle,
  CategoryStyle,
} from "../../styles/Products/productsStyles";
import { useTranslation } from "react-i18next";
import { ConsoleView } from "react-device-detect";

const Categories = (props) => {
  const { t, i18n } = useTranslation();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const regex = /\/([a-zA-Z0-9]{2})\//;
    const match = regex.exec(window.location.href);
    var categoryName = "";
    if (match) {
      if (match[1] == "en") {
        categoryName = "nameEng";
      } else {
        categoryName = "nameRo";
      }
    } else {
      if (i18n.language == "en") {
        categoryName = "nameEng";
      } else {
        categoryName = "nameRo";
      }
    }

    const getData = () => {
      fetch("/locales/fileDatabase/database.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          const categories = myJson.categoriesJson.map(
            (obj) => obj[t(categoryName)]
          );
          setCategories(categories);
        })
        .catch((error) => {
          console.log("error: " + error);
          this.setState({ requestFailed: true });
        });
    };

    getData();
  }, []);

  function changeActiveSubcategories(changedSubcategory) {
    props.changeSubcategoriesFunction(changedSubcategory);
  }

  function handleClick(e) {
    props.changeCategoriesFunction(e.target.value);
  }

  function checkedCategoryFunction(category) {
    if (props.initiallyActiveCategories.includes(category)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div style={CategoryStyle}>
      <b style={FilterTextStyle}>{t("products.categoriesText")}</b>
      {categories.map((category) => (
        <div key={category}>
          <Subcategories
            isCategoryChecked={checkedCategoryFunction(category)}
            category={category}
            changeSubcategoriesFunction={changeActiveSubcategories}
            changeCategoriesFunctionHandler={handleClick}
          />
        </div>
      ))}
    </div>
  );
};

export default Categories;
