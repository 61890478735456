import { useEffect } from "react";

const InvalidPage = () => {
  useEffect(() => {
    const forceInvalid = () => {
      fetch("/locales/fileDatabase/mockDatabase.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .catch((error) => {});
    };

    forceInvalid();
  }, []);

  return (
    <div style={{ minHeight: "100vh" }}>
      <p
        style={{
          textAlign: "center",
          paddingTop: "30vh",
          marginBottom: 0,
          fontSize: "5rem",
          fontWeight: "bold",
        }}
      >
        404
      </p>
      <p
        style={{
          textAlign: "center",
          fontSize: "2.3rem",
          wordSpacing: 4,
          fontWeight: "bold",
        }}
      >
        Page not found
      </p>
    </div>
  );
};

export default InvalidPage;
