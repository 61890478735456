import React from "react";
import "./buttonStyle.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  searchFormStyle,
  searchTextStyle,
  searchButtonStyle,
} from "../../styles/Navbar/navbarStyles";

function SearchBar() {
  const { i18n, t } = useTranslation();
  const language = i18n.language.includes("ro") ? "ro" : "en";

  const [searchFilter, setSearchFilter] = useState([]);

  const navigate = useNavigate();

  function handleChange(e) {
    var searchValue = e.target.value.replace(/\/.*/g, "");
    searchValue = searchValue.replace(/[^a-zA-Z0-9]/g, "");
    setSearchFilter(searchValue);
  }

  function onClick() {
    if (!searchFilter.localeCompare("")) {
      navigate("/" + language + "/search/Invalid request");
    } else {
      navigate("/" + language + "/search/" + searchFilter);
      window.location.reload(false);
    }
  }

  function onKeyUp(event) {
    if (event.charCode === 13) {
      onClick();
    }
  }

  return (
    <form method="get" style={searchFormStyle}>
      <input
        type="text"
        placeholder={t("navbar.searchPlaceholder")}
        name="s"
        onChange={handleChange}
        onKeyPress={onKeyUp}
        style={searchTextStyle}
      />
      <button type="button" onClick={onClick} style={searchButtonStyle}>
        <FontAwesomeIcon icon={faSearch} className="fas fa-camera fa-lg" />
      </button>
    </form>
  );
}

export default SearchBar;
