import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Product from "../../components/Product/product";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function ProductPage() {
  const { t, i18n } = useTranslation();
  const [product, setProduct] = useState([]);
  const { id } = useParams();
  const wordsToExclude = new Set([
    "and",
    "with",
    "grams",
    "pcs",
    "set",
    "cu",
    "din",
    "si",
    "grame",
    "de",
    "on",
    "3pcs",
  ]);

  var description = t("product.description");
  var keywords = t("product.keywords");

  useEffect(() => {
    window.scrollTo(0, 0);
    //language update by url
    const regex = /\/([a-zA-Z0-9]{2})\//;
    const match = regex.exec(window.location.href);
    if (match) {
      const result = match[1];
      if (i18n.language != result) {
        i18n.changeLanguage(result);
      }
    }

    const getData = () => {
      fetch("/locales/fileDatabase/database.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          var product = myJson.productsJson.filter(
            (obj) => obj.id === parseInt(id)
          );
          setProduct(product[0]);
        })
        .catch((error) => {
          console.log("error: " + error);
          this.setState({ requestFailed: true });
        });
    };

    getData();
  }, []);

  if (product.length !== 0) {
    return (
      <div>
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-249926206-1"
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-249926206-1');
        `}
          </script>
          <title>
            Perind -{" "}
            {product[t("product.varName")]
              .replace(/[ăâ]/g, "a")
              .replace(/[ș]/g, "s")
              .replace(/[ț]/g, "t")}
          </title>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
          ></meta>
          <meta name="description" content={description} />
          <meta
            name="keywords"
            content={
              product[t("product.varName")]
                .toLowerCase()
                .replace(/[ăâ]/g, "a")
                .replace(/[ș]/g, "s")
                .replace(/[ț]/g, "t")
                .replace(/\//g, " ")
                .replace(
                  new RegExp(`model\\s+(\\d+)`, "g"),
                  (match, number) => `model${number}`
                )
                .replace(/\s[0-9]+\s/g, " ")
                .replace(/\s\s+/g, " ")
                .replace(/\s/g, ",")
                .split(",")
                .filter((word) => !wordsToExclude.has(word))
                .join(", ") + ", perind.ro"
            }
          ></meta>
        </Helmet>
        <Product
          name={product[t("product.varName")]
            .replace(/[ăâ]/g, "a")
            .replace(/[ș]/g, "s")
            .replace(/[ț]/g, "t")}
          path={product.productImagePath}
          info={product.productInfo}
        />
      </div>
    );
  } else {
    return (
      <Helmet>
        <title>Perind</title>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords}></meta>
      </Helmet>
    );
  }
}

export default ProductPage;
