import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconStyle,
  TelephoneContactCardStyle,
  TelephoneContactCardTextStyle,
  TypographyStyle,
} from "../../styles/Contact/contactStyles";

import Contacts from "../../constants/Contact/contactConstants.json";

const contacts = [
  {
    contactPhoneNumber: Contacts.contact1PhoneNumber,
    contactName: Contacts.contact1Name,
    contactPosition: Contacts.contact1Position,
    contactEmail: Contacts.contact1Email,
  },
  {
    contactPhoneNumber: Contacts.contact2PhoneNumber,
    contactName: Contacts.contact2Name,
    contactPosition: Contacts.contact2Position,
    contactEmail: Contacts.contact2Email,
  },
  {
    contactPhoneNumber: Contacts.contact3PhoneNumber,
    contactName: Contacts.contact3Name,
    contactPosition: Contacts.contact3Position,
    contactEmail: Contacts.contact3Email,
  },
];

const TelephoneContactCard = () => {
  return (
    <div>
      {contacts.map((contact) => (
        <Card
          style={TelephoneContactCardStyle}
          key={contact.contactPhoneNumber}
        >
          <CardActionArea>
            <CardContent style={TelephoneContactCardTextStyle}>
              <Typography style={TypographyStyle}>
                {contact.contactPosition}
                <br></br>
              </Typography>
              <Typography>
                {contact.contactName}
                <br></br>
                <br></br>
                <FontAwesomeIcon icon={faPhone} style={IconStyle} /> :{" "}
                {contact.contactPhoneNumber}
                <br></br>
                <br></br>
                <FontAwesomeIcon icon={faEnvelope} style={IconStyle} /> :{" "}
                {contact.contactEmail}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </div>
  );
};

export default TelephoneContactCard;
