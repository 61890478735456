import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {
  logoImageStyle,
  logoStyle,
  motoImageStyle,
  motoStyle,
} from "../../styles/Navbar/navbarStyles";

const Logo = () => {
  return (
    <Container fluid={true} width="70px">
      <Row>
        <Col xs={1}></Col>
        <Col xs={4} md={3} style={logoStyle}>
          <img
            src={require("../../designImages/Logo.png")}
            alt="Logo Perind"
            style={logoImageStyle}
          ></img>
        </Col>
        <Col xs={1} md={2}></Col>
        <Col xs={5} md={5} style={motoStyle}>
          <img
            src={require("../../designImages/Moto2.png")}
            alt="Moto Perind"
            style={motoImageStyle}
          ></img>
        </Col>
        <Col xs={1}></Col>
      </Row>
    </Container>
  );
};

export default Logo;
