import React from "react";
import Parser from "html-react-parser";
import EmailContactForm from "./emailContactForm";
import { useTranslation } from "react-i18next";
import {
  EmailContactTitleTextStyle,
  EmailContactParagraphTextStyle,
} from "../../styles/Contact/contactStyles";

const EmailContact = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h3 style={EmailContactTitleTextStyle}>
        {t("contact.emailContactTitle")}
      </h3>
      <br></br>
      <br></br>
      <p style={EmailContactParagraphTextStyle}>
        {Parser(t("contact.emailContactParagraph"))}
      </p>
      <br></br>
      <br></br>
      <EmailContactForm />
    </div>
  );
};

export default EmailContact;
