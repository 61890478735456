export const CardTextStyle = {
  fontSize: 16,
  justifyContent: "Center",
  textAlign: "Center",
};

export const CellStyle = {
  paddingBottom: "20px",
  paddingTop: "20px",
};

export const FilterStyle = {
  display: "flex",
  justifyContent: "Center",
  alignItems: "Top",
  // background: "#007CC2",
  paddingTop: "30px",
  paddingBottom: "60px",
};

export const FilterTextStyle = {
  color: "#101010",
  lineHeight: 2,
  wordSpacing: 4,
  fontWeight: "bold",
  fontSize: 32,
  textAlign: "center",
  paddingTop: "50px",
  paddingBottom: "80px",
};

export const CategoriesTextStyle = {
  color: "#101010",
  fontSize: 20,
  fontWeight: "bold",
};

export const CategoriesCheckboxStyle = {
  color: "#101010",
  paddingLeft: "10px",

  paddingBottom: "15px",
};

export const SubcategoriesTextStyle = {
  color: "#101010",
  fontSize: 14,
  paddingTop: "2px",
};

export const SubcategoriesCheckboxStyle = {
  color: "#101010",
  paddingLeft: "20px",
  paddingTop: "10px",
};

export const IconStyle = {
  paddingLeft: "5px",
  paddingTop: "5px",
};

export const ProductPageStyle = {
  background: "#f2f2f7",
  width: "99vw",
};

export const ProductPageContainerStyle = {
  paddingTop: "10px",
  paddingBottom: "60px",
  minHeight: "100vh",
};

export const ProductPageRowStyle = {
  paddingTop: "5vh",
};

export const CategoryStyle = {
  paddingTop: "30px",
};

export const SubcategoryStyle = {
  paddingTop: "0px",
};

export const SearchPageStyle = {
  background: "#f2f2f7",
  width: "99vw",
};

export const SearchPageContainerStyle = {
  paddingTop: "10px",
  paddingBottom: "60px",
  minHeight: "100vh",
};

export const SearchPageRowStyle = {
  paddingTop: "5vh",
};

export const SearchTextStyle = {
  lineHeight: 2,
  wordSpacing: 4,
  fontSize: 16,
  fontWeight: "bold",
  paddingBottom: "20px",
};

export const CardStyle = {
  height: "100%",
};

export const CardMediaStyle = {
  paddingBottom: "20px",
};
